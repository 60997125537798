var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"_id","show-select":"","single-select":false,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.appsData,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.channels.app.build.app_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.channels.app.build.app_name.replace(/"/g, ''))+" ")]}},{key:"item.channels.app.push.credentials.firebase_version",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAppFirebaseProject(item))+" ")]}},{key:"item.channels.app.build.app_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAppType(item.channels.app.build.app_type))+" ")]}},{key:"item.channels.app.build.assets.icon_ios",fn:function(ref){
var item = ref.item;
return [(item.channels.app.build.assets)?_c('v-avatar',{attrs:{"size":"42"}},[_c('img',{attrs:{"src":item.channels.app.build.assets.icon_ios || '',"alt":"icon"}})]):_c('v-avatar',{attrs:{"size":"42"}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-alert-circle")])],1)]}},{key:"item.channels.app.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.channels.app.active ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.channels.app.active ? 'Ativo' : 'Inativo')+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToAppConfig(item._id)}}},[_c('v-list-item-title',[_vm._v("Configurações do app")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedApps),callback:function ($$v) {_vm.selectedApps=$$v},expression:"selectedApps"}})}
var staticRenderFns = []

export { render, staticRenderFns }