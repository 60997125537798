<template>
  <v-data-table
    v-model="selectedApps"
    item-key="_id"
    show-select
    :single-select="false"
    :loading="loading"
    :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
    :server-items-length="maxRecords"
    :options.sync="options"
    :headers="headers"
    :items="appsData"
    :search="search"
  >
    <template v-slot:[`item.channels.app.build.app_name`]="{ item }">
      {{ item.channels.app.build.app_name.replace(/"/g, '') }}
    </template>

    <template v-slot:[`item.channels.app.push.credentials.firebase_version`]="{ item }">
      {{ getAppFirebaseProject(item) }}
    </template>

    <template v-slot:[`item.channels.app.build.app_type`]="{ item }">
      {{ getAppType(item.channels.app.build.app_type) }}
    </template>

    <template v-slot:[`item.channels.app.build.assets.icon_ios`]="{ item }">
      <v-avatar v-if="item.channels.app.build.assets" size="42">
        <img :src="item.channels.app.build.assets.icon_ios || ''" alt="icon" />
      </v-avatar>
      <v-avatar v-else size="42">
        <v-icon class="ma-2">mdi-alert-circle</v-icon>
      </v-avatar>
    </template>

    <template v-slot:[`item.channels.app.active`]="{ item }">
      <v-icon class="mr-2" x-small :color="item.channels.app.active ? 'success' : 'error'">mdi-brightness-1</v-icon
      >{{ item.channels.app.active ? 'Ativo' : 'Inativo' }}
    </template>

    <template v-slot:[`item.options`]="{ item }">
      <v-menu :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="goToAppConfig(item._id)">
            <v-list-item-title>Configurações do app</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>
<script>
import { QUERY_GET_AVAILABLE_APPS } from '@/modules/app/graphql/index.js'

export default {
  props: {
    search: {
      type: String,
      default: () => ''
    },
    appsList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {}
    },
    refetch: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    appsData: [],
    options: {},
    maxRecords: 0,
    loadingAction: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Avatar',
          value: 'channels.app.build.assets.icon_ios',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Nome',
          value: 'channels.app.build.app_name',
          align: 'center'
        },
        {
          text: 'Tipo',
          value: 'channels.app.build.app_type',
          sortable: true,
          align: 'center'
        },
        {
          text: 'Firebase',
          value: 'channels.app.push.credentials.firebase_version',
          sortable: true,
          align: 'center'
        },
        {
          text: 'ID Cliente',
          value: 'channels.app.build.account_id',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Status',
          value: 'channels.app.active',
          align: 'center'
        },
        {
          text: 'IOS Version',
          value: 'channels.app.build.store.ios_version_code',
          align: 'center'
        },
        {
          text: 'IOS Build',
          value: 'channels.app.build.store.ios_build_code',
          align: 'center'
        },
        {
          text: 'Android Version',
          value: 'channels.app.build.store.android_version_code',
          align: 'center'
        },
        {
          text: 'Android Build',
          value: 'channels.app.build.store.android_build_code',
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          sortable: false,
          align: 'center'
        }
      ]
    },
    loading() {
      return this.$apollo.loading || this.loadingAction
    },
    selectedApps: {
      get() {
        return this.appsList
      },
      set(value) {
        this.$emit('update:appsList', value)
      }
    }
  },
  apollo: {
    apps: {
      query: QUERY_GET_AVAILABLE_APPS,
      fetchPolicy: 'network-only',
      variables() {
        const pagination = Object.assign(
          {},
          { page: this.options.page || 1 },
          { pageSize: this.options.itemsPerPage || 10 },
          { search: this.search || null },
          { sort: this.options.sortBy || ['_id'] },
          { sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC')) }
        )

        return {
          pagination,
          filters: this.filters
        }
      },
      update({ apps: { count, data } }) {
        this.maxRecords = count
        this.appsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    }
  },
  watch: {
    refetch(val) {
      // eslint-disable-next-line no-useless-return
      if (!val) return

      this.$apollo.queries.apps.refetch()
      this.$emit('update:refetch', false)
    },
    search(val) {
      this.options.page = 1
    }
  },
  methods: {
    goToAppConfig(accountId) {
      window.open(this.$router.resolve(`/accounts/${accountId}/app-config`).href, '_blank')
    },
    getAppType(val) {
      return val?.charAt(0).toUpperCase() + val?.slice(1) || '-'
    },
    getAppFirebaseProject(item) {
      return +item.channels.app?.push?.credentials?.firebase_version || '-'
    }
  }
}
</script>
